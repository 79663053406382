import React from 'react'
//import TopBar from './TopBar'
import Footer from './Footer'
import Header from './Header'
import { makeStyles } from '@material-ui/core/styles';

const alturaDrawerTop = 0

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 0),
        // necessary for content to be below app bar
        //...theme.mixins.toolbar,
        height: `${alturaDrawerTop}vh`
    }
}));

const Layout = (props) => {
    const classes = useStyles();
    const { children } = props;
    return (
        <div className={classes.root} style={{ display: 'flex' }}>
            {/*<TopBar />*/}
            {/*<Drawer/>
            <DrawerSwipeable/>*/}
            <main className={classes.content} style={{ backgroundColor: 'black', padding: '0px', overflow: "hidden"}}>
                <div className={classes.toolbar} />
                <Header />
                {children}
                <Footer />
            </main>
        </div>
    )
}

export default Layout
