import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import AlphabetMx from "../../assets/AlphabetMx_Logo_SinManchasBlancas_Recortado.png";
import { motion as m } from "framer-motion";


//const alturaHome = 100;
//const AlturaPaddingDrawer = alturaHome / 2;
//const TamanoIcono = 10;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))



function Carga() {
    // Redes 
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        //console.log("HOLAAAAAAAAAAAAAA");

        setTimeout(() => {
            //console.log("Adios");

            setTimeout(() => {
                //window.location.replace('/inicio');
                navigate('/inicio');
            }, 2000);

        }, 500);
    }, [navigate]); //Se puede quitar el navigate y en vez de eso poner eslint-disable-next-line arriba de esta linea para solo ocultar el warning

    return (
        <m.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.25, ease: "easeOut" }}
            exit={{ opacity: 1 }}
        >
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden" }}>
                    <header className="App-container">

                        {/* ESTE ES EL QUE QUIERO DEJAR */}
                        <Box //Header Icono Home
                            id='HomeTopBar' justifyContent="center" display='flex'
                            style={{
                                margin: 0,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                            }} >
                            <m.a
                                initial={{ scale: 0/*, y: 1000*/ }}
                                animate={{ scale: 3/*, y: 0*/ }}
                                transition={{ delay: 0.0, duration: 0.5 }}
                                exit={{ scale: 0 }}
                                href="/inicio" target="_self" rel="noreferrer">
                                <Box
                                    component="img"
                                    sx={{
                                        height: "auto",
                                        width: "25vw",
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 100000
                                    }}
                                    alt="AlphabetMx."
                                    src={AlphabetMx}
                                />
                            </m.a>
                        </Box>

                    </header>
                </div>
            </div>
        </m.div>
    );
}

export default Carga;