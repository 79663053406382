import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Box from '@mui/material/Box';
import AlphabetMx from "../../assets/alphabetmx.png";
import { useNavigate, Link, withParams } from 'react-router-dom';

import { motion as m } from "framer-motion";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

const Footer = () => {
    const navigate = useNavigate();
    // Inicio 
    const classes = useStyles();
    const PagExcluidas = [
        '/',
        '/carga',
    ]
    const PagInicio = [
        '/inicio',
    ]
    const PagActual = window.location.pathname;

    function colorear() {
        setTimeout(() => {
            const FooterCargado = document.getElementById("Id_Footer");

            const Boton_ = document.getElementById("Nav_");
            const BotonCarga = document.getElementById("Nav_Carga");
            const BotonInicio = document.getElementById("Nav_Inicio");
            const BotonBolsas = document.getElementById("Nav_Bolsas");

            Boton_.style.backgroundColor = "red";
            BotonCarga.style.backgroundColor = "red";
            BotonInicio.style.backgroundColor = "red";
            BotonBolsas.style.backgroundColor = "red";

            if (FooterCargado) {
                switch (PagActual) {
                    case '/inicio':
                        return (
                            BotonInicio.style.backgroundColor = "green",
                            BotonInicio.innerHTML = "Si es inicio"
                        )
                    case '/bolsas':
                        return (
                            BotonBolsas.style.backgroundColor = "green",
                            BotonBolsas.innerHTML = "Si es bolsas"
                        )
                    default:
                        console.log("No Switch");
                }
            }
        }, 100);
    }

    const tiempo = 1;
    const container = {
        hidden: {
            y: 100,
            opacity: 0,
            scale: 1
        },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
                delay: tiempo,
                duration: tiempo,
                delayChildren: tiempo + 1, //Lo que tarda para empezar los children a mostrarse
                staggerChildren: 0.3 //Tiempo entre childrens
            }
        }
    };

    const item = {
        hidden: {
            y: 20,
            opacity: 0.3,
            scale: 0.5
        },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1.0
        }
    };

    if (PagExcluidas.indexOf(PagActual) >= 0) {
        return (
            <div>
            </div>
        )
    } else if (PagExcluidas.indexOf(PagActual) < 0) {
        colorear();
        return (
            <m.div id="Id_Footer"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.75, ease: "easeOut" }}
                exit={{ opacity: 0 }}
            >
                <div className={classes.otro}>
                    <div className="App" style={{ overflowY: "hidden" }}>
                        <m.header variants={container}
                            initial="hidden"
                            animate="visible" className="App-footer">

                            <m.div style={{ display: "flex" }}>
                                <m.div id="Nav_" className="TransicionBotones" variants={item} layout
                                    /*initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.3, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "-50vh", x: "-50vw" }}*/
                                    onClick={() => navigate("/")}>/
                                </m.div>
                                <m.div id="Nav_Carga" className="TransicionBotones" variants={item}
                                    onClick={() => navigate("/carga")}>/carga
                                </m.div>
                                <m.div id="Nav_Inicio" className="TransicionBotones" variants={item}
                                    onClick={() => navigate("/inicio")}>/inicio
                                </m.div>
                                <m.div id="Nav_Bolsas" className="TransicionBotones" variants={item}
                                    onClick={() => navigate("/bolsas")}>/bolsas
                                </m.div>
                            </m.div>

                            <div style={{ display: "none" }}>
                                <button component={Link} to="/inicio">HButton</button>
                                <Link to="/inicio" relative="path" style={{ color: "red" }}> HLink </Link> {/* ESTA MADRE NOMAS CARGA EL HEADER A LA PAGINA DE CARGA */}
                                <IconButton aria-label="home" size='small' component={Link} to="/inicio">HIconButton</IconButton>
                            </div>

                        </m.header>
                    </div>
                </div>
            </m.div>
        );
    }
}

export default Footer;