import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion as m, useScroll, useTransform } from "framer-motion";

const ProgressBarVertical = () => {
    const { scrollYProgress } = useScroll();
    const scale = useTransform(scrollYProgress, [0, 1], [1, 1]);

    // Inicio 
    const PagExcluidas = ['/', '/carga',]
    const PagActual = window.location.pathname;

    const tiempo = 1;
    const container = {
        hidden: {
            y: -100,
            opacity: 0,
            scale: 1
        },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
                duration: tiempo,
                delayChildren: tiempo + 0.3, //Lo que tarda para empezar los children a mostrarse
                staggerChildren: 0.3 //Tiempo entre childrens
            }
        }
    };

    const item = {
        hidden: {
            y: 20,
            opacity: 0.3,
            scale: 0.5
        },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1.0
        }
    };

    if (PagExcluidas.indexOf(PagActual) >= 0) {
        return (
            <div style={{ color: "white" }}>
            </div>
        )
    } else if (PagExcluidas.indexOf(PagActual) < 0) {
        return (
            <div className="wrapper" >
                <m.div variants={container}
                    initial="hidden"
                    animate="visible"
                    className="RellenoScroll"
                    style={{
                        scale
                    }}
                >
                    <m.div variants={item}
                        className="item"
                        style={{
                            scaleY: scrollYProgress
                        }}
                    />
                </m.div>
            </div>
        );
    }
}

export default ProgressBarVertical;