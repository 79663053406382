import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion as m, useScroll } from "framer-motion";

const ProgressBarHorizontal = () => {
    const { scrollYProgress } = useScroll();

    // Inicio 
    const PagExcluidas = ['/', '/carga',]
    const PagActual = window.location.pathname;


    if (PagExcluidas.indexOf(PagActual) >= 0) {
        return (
            <div style={{ color: "white" }}>
            </div>
        )
    } else if (PagExcluidas.indexOf(PagActual) < 0) {
        return (
            <m.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress, backgroundColor: "#605446" }}
            >
                AlphabetMx @ 2023
            </m.div>
        );
    }
}

export default ProgressBarHorizontal;