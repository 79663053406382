import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { motion as m } from "framer-motion";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as AlphabetMxSVG } from '../../assets/AlphabetMx5.svg';
import AlphabetMx from "../../assets/alphabetmx.png";

import { useInView } from "react-intersection-observer";

//const alturaHome = 100;
const alturaDrawerTop = 50;
//const AlturaPaddingDrawer = alturaDrawerTop / 2;
const TamanoIcono = 100;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

function Bolsas() {
    const { ref, inView } = useInView({
        threshold: 1
      });

    const navigate = useNavigate();
    // Inicio 
    const classes = useStyles();

    //document.getElementById("HomeTopBar").style.display = "block";

    return (
        <m.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 1 }}
        >
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden" }}>
                    <header className="App-container">

                        <h1 style={{ color: "black" }}>Bols</h1>
                        <button onClick={() => navigate("/inicio")}>A Fuerzas</button>

                        <Container style={{ color: "red" }}>
                            <Row>
                                <m.div>
                                    <Col>1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>
                        <Container style={{ color: "red" }}>
                            <Row>
                                <m.div>
                                    <Col>1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>
                        <Container style={{ color: "red" }}>
                            <Row>
                                <m.div>
                                    <Col>1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>
                        <Container style={{ color: "red" }}>
                            <Row>
                                <m.div>
                                    <Col>1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>
                        <Container ref={ref} style={{ color: "green", fontSize:"1vw" }}>
                            <Row>
                                <m.div>
                                    <Col>{inView.toString()} 1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>

                        <Container className='h-100' style={{ display: "none", visibility: "visible", padding: 0, margin: 0, width: "100%", height: "100%" }}>
                            <Row>
                                <m.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 1, duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "-50vh", x: "-50vw" }}
                                >
                                    <Col style={{ color: "#6A584A", fontSize: "8vw", fontWeight: "bold" }}>Próximamente...</Col>
                                </m.div>

                                <m.div

                                >
                                    <Col style={{ padding: "5vg", borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>

                                        <m.div
                                            whileHover={{ scale: 5.0 }}
                                            onHoverStart={e => { }}
                                            onHoverEnd={e => { }}
                                        >
                                            <AlphabetMxSVG width={`${TamanoIcono}vw`} style={{ margin: "10px" }} fill='var(--text-primary)' />

                                        </m.div>

                                    </Col>
                                </m.div>

                            </Row>
                        </Container>

                        <Container className='h-100' style={{ display: "none", visibility: "visible", padding: 0, margin: 0, width: "100%", height: "100%" }}>
                            <Row style={{ height: "100vh", width: "100vw", margin: 0 }}>

                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "-50vh", x: "-50vw" }}
                                >

                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Menú" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/menu">

                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: { xs: "auto", md: "auto" },
                                                                width: { xs: "auto", md: "auto" },
                                                                maxWidth: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                                maxHeight: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                                //maxWidth: { xs: 48, md: 60 },
                                                            }}
                                                            alt="AlphabetMx."
                                                            src={AlphabetMx}
                                                        />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>

                                </m.div>
                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "-50vh", x: "50vw" }}
                                >
                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Ubicación" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/ubicacion">
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: { xs: "auto", md: "auto" },
                                                                width: { xs: "auto", md: "auto" },
                                                                maxWidth: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                                maxHeight: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw`, lg: `${TamanoIcono}vw` }
                                                                //maxWidth: { xs: 48, md: 60 },
                                                            }}
                                                            alt="AlphabetMx."
                                                            src={AlphabetMx}
                                                        />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>
                                </m.div>
                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "50vh", x: "-50vw" }}
                                >
                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Redes" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/redes">
                                                        <AlphabetMxSVG width={`${TamanoIcono}vw`} fill='var(--text-primary)' />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>
                                </m.div>
                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "50vh", x: "50vw" }}
                                >
                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Otro" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/otro">
                                                        <MoreHorizIcon /*fontSize="large"*/ sx={{ fontSize: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw`, lg: `${TamanoIcono}vw` }, color: "var(--text-primary)" }} />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>
                                </m.div>


                                {/*<Col className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6' style={{ boxShadow:"inset 0 0 100px orange", padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  4
                </Col>*/}

                            </Row>
                        </Container>


                    </header>
                </div>
            </div>
        </m.div>
    );
}

export default Bolsas;