import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { motion as m } from "framer-motion";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as AlphabetMxSVG } from '../../assets/AlphabetMx5.svg';
import AlphabetMx1 from "../../assets/alphabetmx.png";
import AlphabetMx2 from "../../assets/AlphabetMx_Logo_SinManchasBlancas.png";
import AlphabetMx3 from "../../assets/AlphabetMx_Logo_SinManchasBlancas_Recortado.png";
import AlphabetMx4 from "../../assets/AlphabetMx_Logo_Original.png";

import ochok from "../../assets/8k.png";

import Skeleton from '@mui/material/Skeleton';

import Imagen from '../Elements/Imagen'

const config = {
    //rootMargin: "bottom right up left",
    rootMargin: "0px 0px 0px 0px",
    threshold: 1.0,
};

//const alturaHome = 100;
const alturaDrawerTop = 50;
//const AlturaPaddingDrawer = alturaDrawerTop / 2;
const TamanoIcono = 100;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

function Inicio(props) {
    const [imagenes, setImagenes] = useState([
        {
            titulo: 'Iguana',
            path: "" + AlphabetMx1 + ""
        },
        {
            titulo: 'Comida',
            path: 'https://images.pexels.com/photos/12815070/pexels-photo-12815070.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
        },
        {
            titulo: 'Bici',
            path: 'https://images.pexels.com/photos/12894053/pexels-photo-12894053.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            titulo: 'Bloor',
            path: 'https://images.pexels.com/photos/11824539/pexels-photo-11824539.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15129222/pexels-photo-15129222.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/4946595/pexels-photo-4946595.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5207636/pexels-photo-5207636.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5326957/pexels-photo-5326957.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/16115229/pexels-photo-16115229.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15943127/pexels-photo-15943127.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5074828/pexels-photo-5074828.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15682443/pexels-photo-15682443.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/14831985/pexels-photo-14831985.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/12901382/pexels-photo-12901382.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15666848/pexels-photo-15666848.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/11446333/pexels-photo-11446333.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5346060/pexels-photo-5346060.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/14653318/pexels-photo-14653318.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
    ]);

    const [loaded, setLoaded] = useState({});
    const [imageStatus, setImageStatus] = useState({});

    const navigate = useNavigate();

    const classes = useStyles();

    /** Array a H1 con un forEach */
    const studentDetails = ['Alex', 'Anik', 'Deven', 'Rathore'];
    const names = [];
    studentDetails.forEach((data) => {
        names.push(<h3 className='student_name'>{data}</h3>)
    })

    useEffect(() => {
        const myArray = [10, 20, 30, 40, 50];
        myArray.forEach((el) => {
            console.log(el)
        });

        let observer = new window.IntersectionObserver(function (entries, self) {
            console.log("Detectando...");

            setTimeout(() => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        loadImages(entry.target);
                        self.unobserve(entry.target);
                    }
                });
            }, 100);
        }, config);

        const imgs = document.querySelectorAll("[data-src]");
        imgs.forEach((img) => {
            observer.observe(img);
        });
        return () => {
            imgs.forEach((img) => {
                observer.unobserve(img);
            });
        };
    }, []);

    const loadImages = (image) => {
        const src = image.dataset.src;
        const img = new Image();
        img.onload = () => {
            setLoaded(prevState => ({ ...prevState, [src]: true }));
            setImageStatus(prevState => ({ ...prevState, [src]: true }));
        }
        img.src = src;
    };

    return (
        <m.div style={{/*overflowY:"scroll"*/ }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 1 }}
        >
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden", overflowX: "hidden" }}>
                    <header className="App-container" style={{ display: "flex" }}>

                        <div style={{ height: "90vh", width: "100vw", backgroundColor: "" }} >
                            <h1 style={{ color: "black" }}>Inicio</h1>
                            {names}
                        </div>

                        <div className="row" style={{ marginBottom: "10px", width:"100%" }}>
                            {imagenes.map((imagen) => {
                                console.log(imagen.path);
                                return (
                                    <Imagen
                                        titulo={imagen.titulo}
                                        path={imagen.path}
                                    />
                                );
                            })}
                        </div>

                        {/*imageStatus[AlphabetMx1] ? (
                            <img
                                src={AlphabetMx1}
                                data-src={AlphabetMx1}
                                className={"mientras1"}
                                alt=""
                            />
                        ) : (
                            <div data-src={AlphabetMx1} width={"100vw"} >
                                <Skeleton
                                    sx={{ bgcolor: '#605446', display: "inline-grid", borderStyle: "solid", borderWidth: "0.1vw", borderColor: "red" }}
                                    variant="rectangular"
                                    width={"50vw"}
                                    height={"50vh"}
                                />
                            </div>
                        )}

                        {imageStatus[AlphabetMx2] ? (
                            <img
                                src={AlphabetMx2}
                                data-src={AlphabetMx2}
                                className={"mientras2"}
                                alt=""
                            />
                        ) : (
                            <div data-src={AlphabetMx2} width={"100vw"} >
                                <Skeleton
                                    sx={{ bgcolor: '#605446', display: "inline-grid", borderStyle: "solid", borderWidth: "0.1vw", borderColor: "red" }}
                                    variant="rectangular"
                                    width={"50vw"}
                                    height={"50vh"}
                                />
                            </div>
                        )*/}


                        {/*<div data-src={AlphabetMx3} className={"mientras2"} />
                        <div data-src={AlphabetMx4} className={"mientras1"} />
                        <div data-src={ochok} className={"mientras2"} />*/}


                        <h1 style={{ color: "green" }}>algoo</h1>



                        <m.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1.0, ease: "easeOut" }}
                            exit={{ scale: 0.0, y: "-50vh", x: "-50vw" }}
                            onClick={() => navigate("/bolsas")}>A Fuerzas
                        </m.button>

                        <Container className='h-80' style={{ display: "none", padding: 0, margin: 0, width: "100%", height: "80%" }}>
                            <Row>
                                <m.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 1, duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "-50vh", x: "-50vw" }}
                                >
                                    <Col style={{ color: "#6A584A", fontSize: "8vw", fontWeight: "bold" }}>Próximamente...</Col>
                                </m.div>

                                <m.div

                                >
                                    <Col style={{ padding: "5vh", borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>

                                        <m.div
                                            whileHover={{ scale: 5.0 }}
                                            onHoverStart={e => { }}
                                            onHoverEnd={e => { }}
                                        >
                                            <AlphabetMxSVG width={`${TamanoIcono}vw`} style={{ margin: "10px" }} fill='var(--text-primary)' />

                                        </m.div>

                                    </Col>
                                </m.div>

                            </Row>
                        </Container>

                        <Container className='h-80' style={{ display: "none", visibility: "visible", padding: 0, margin: 0, width: "100%", height: "80%" }}>
                            <Row style={{ height: "100vh", width: "100vw", margin: 0 }}>

                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "-50vh", x: "-50vw" }}
                                >

                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Menú" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/menu">

                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: { xs: "auto", md: "auto" },
                                                                width: { xs: "auto", md: "auto" },
                                                                maxWidth: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                                maxHeight: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                                //maxWidth: { xs: 48, md: 60 },
                                                            }}
                                                            alt="AlphabetMx1."
                                                            src={AlphabetMx1}
                                                        />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>

                                </m.div>
                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "-50vh", x: "50vw" }}
                                >
                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Ubicación" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/ubicacion">
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: { xs: "auto", md: "auto" },
                                                                width: { xs: "auto", md: "auto" },
                                                                maxWidth: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw` },
                                                                maxHeight: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw`, lg: `${TamanoIcono}vw` }
                                                                //maxWidth: { xs: 48, md: 60 },
                                                            }}
                                                            alt="AlphabetMx1."
                                                            src={AlphabetMx1}
                                                        />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>
                                </m.div>
                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "50vh", x: "-50vw" }}
                                >
                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Redes" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/redes">
                                                        <AlphabetMxSVG width={`${TamanoIcono}vw`} fill='var(--text-primary)' />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>
                                </m.div>
                                <m.div style={{ padding: "0px", display: "flex" }}
                                    className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5, ease: "easeOut" }}
                                    exit={{ scale: 0.0, y: "50vh", x: "50vw" }}
                                >
                                    <Col style={{ borderStyle: "solid", borderWidth: "0.3vh", borderColor: "black", boxShadow: "inset 0 0 2vh #000000", padding: 0, display: "flex", /*flexDirection:"column",*/ justifyContent: "center", alignItems: "center" }}>
                                        <Box //Header Icono Home
                                            id='HomeTopBar' justifyContent="center" display='flex' flexGrow={1}>
                                            <Tooltip TransitionComponent={Zoom} title="Otro" arrow placement="bottom">
                                                <m.div
                                                    whileHover={{ scale: 1.2 }}
                                                    onHoverStart={e => { }}
                                                    onHoverEnd={e => { }}
                                                >
                                                    <IconButton style={{ pointerEvents: "auto", zIndex: 2000000, height: `${alturaDrawerTop}vh`, width: "50vw", borderRadius: 0 }} aria-label="home" size='large' component={Link} to="/otro">
                                                        <MoreHorizIcon /*fontSize="large"*/ sx={{ fontSize: { xs: `${TamanoIcono}vw`, md: `${TamanoIcono}vw`, lg: `${TamanoIcono}vw` }, color: "var(--text-primary)" }} />
                                                    </IconButton>
                                                </m.div>
                                            </Tooltip>
                                        </Box>
                                    </Col>
                                </m.div>


                                {/*<Col className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6' style={{ boxShadow:"inset 0 0 100px orange", padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  4
                </Col>*/}

                            </Row>
                        </Container>

                        <div className="Divisor" />

                    </header>
                </div>
            </div >
        </m.div >
    );
}

export default Inicio;