import React, { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { Container, Row, Col } from 'react-bootstrap';
import Box from '@mui/material/Box';
import AlphabetMx from "../../assets/alphabetmx.png";
import { useNavigate, Link/*, withParams*/ } from 'react-router-dom';

import { motion as m } from "framer-motion";
import { IconButton } from "@material-ui/core";

import { ReactComponent as AlphabetMxSVG } from '../../assets/AlphabetMx5.svg';

const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

const Header = () => {
    const navigate = useNavigate();
    // Inicio 
    const classes = useStyles();
    const PagExcluidas = ['/', '/carga',]
    const PagActual = window.location.pathname;

    //region Pruebas de exclusion de paginas
    //console.log("Header: " + PagExcluidas.indexOf(PagActual))

    //console.log("Header: " + ["/","/carga","/inicio"].indexOf(PagActual))
    //document.getElementById("HomeTopBar").style.display = "block";
    //console.log(window.location.pathname)
    //console.log((window.location.pathname == "/carga" || "/" || "/inicio" ))
    //
    //#endregion

    const tiempo = 1;
    const container = {
        hidden: {
            y: -100,
            opacity: 0,
            scale: 1
        },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
                duration: tiempo,
                delayChildren: tiempo + 0.3, //Lo que tarda para empezar los children a mostrarse
                staggerChildren: 0.3 //Tiempo entre childrens
            }
        }
    };

    const item = {
        hidden: {
            y: 20,
            opacity: 0.3,
            scale: 0.5
        },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1.0
        }
    };

    const constraintsRef = useRef(null)


    if (PagExcluidas.indexOf(PagActual) >= 0) { // Si es la pantalla de Carga 
        return (
            <div style={{ color: "white" }}>
                AlphabetMx @ 2023
            </div>
        )
    } else if (PagExcluidas.indexOf(PagActual) < 0) {
        return (
            //        if (this.props.location.pathname === '/'){}
            <m.div
                initial={{ opacity: 0 }}
                animate={{ delay: 1, opacity: 1 }}
                transition={{ delay: 0, duration: 0.3, ease: "easeOut" }}
                exit={{ opacity: 0 }}
            >
                {/*<m.ul
                    variants={container}
                    initial="hidden"
                    animate="visible"
                >
                    {[0, 1, 2, 3].map((index) => (
                        <m.li key={index} className="item" variants={item} />
                    ))}
                    <m.li variants={item} />
                    <m.li variants={item} />
                </m.ul>*/}
                <div className={classes.otro}>
                    <div className="App" style={{ overflowY: "hidden" }}>
                        <m.header variants={container}
                            initial="hidden"
                            animate="visible" className="App-header">

                            <m.div ref={constraintsRef} className="containerDrag" width="100%" style={{ color: "red"/*, backgroundColor: "#FEFEFE"*/ }}>
                                <m.div drag dragConstraints={constraintsRef}  className="itemDrag">
                                    <m.div
                                        whileHover={{
                                            scale: 0.9,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        onHoverStart={e => { }}
                                        onHoverEnd={e => { console.log("hola") }}
                                    >
                                        <AlphabetMxSVG className="Responsivo" width={`auto`} style={{ margin: "0px"}} />
                                    </m.div>
                                </m.div>
                            </m.div>

                            <div style={{ display: "none" }}>
                                <button component={Link} to="/inicio">HButton</button>
                                <Link to="/inicio" relative="path" style={{ color: "red" }}> HLink </Link> {/* ESTA MADRE NOMAS CARGA EL HEADER A LA PAGINA DE CARGA */}
                                <IconButton aria-label="home" size='small' component={Link} to="/inicio">HIconButton</IconButton>
                            </div>

                        </m.header>
                    </div>
                </div>
            </m.div>
        );
    }
}

export default Header;