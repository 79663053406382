import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Carga from '../Screens/Carga'
import Inicio from '../Screens/Inicio'
import Bolsas from '../Screens/Bolsas'
import ProgressBarVertical from '../Layout/ProgressBarVertical'
import ProgressBarHorizontal from '../Layout/ProgressBarHorizontal'
/*import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Mitotero from '../Layout/Mitotero'*/

import { AnimatePresence } from 'framer-motion'

/*const exclusionArray = [
    '/',
    '/carga',
]*/
//console.disableYellowBox = true;
const Router = () => {
    const location = useLocation();
    //const ubicacionPath = "prueba";
    return (
        <div>
            <AnimatePresence initial={true} mode={"wait"}>
                {/*exclusionArray.indexOf(location.pathname) < 0 && <Header />*/}
                <ProgressBarVertical />
                <ProgressBarHorizontal />
                <Routes location={location} key={location.pathname}>
                    <Route path="/" exact element={<Carga />} /> {/* Este define la primera pagina a cargar */}
                    <Route exact path="/inicio" element={<Inicio />} component={Inicio} />
                    <Route exact path="/bolsas" element={<Bolsas />} component={Bolsas} />
                    <Route exact path="/carga" element={<Carga />} component={Carga} />
                </Routes>

                {/*exclusionArray.indexOf(location.pathname) < 0 && <Footer />*/}
                {/*{ location.pathname !== '/' && <Footer /> }*/}
            </AnimatePresence>

        </div>
    )
}

export default Router
