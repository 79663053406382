import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './components/Shared/Router';
import Layout from './components/Layout';

console.log("Primera carga en: " + window.location.pathname);

function App() {
  return (
    <Router>
      <div className="App">
        <Layout>
          <Routes />
        </Layout>
      </div>
    </Router>
  );
}

export default App;
